import React, { useState, useEffect } from 'react'

import './App.css';
import logo from './images/logo2.svg';
import man_img from './images/man2.jpg';
import bg from './images/unite-legal-services-finance-and-legal.jpg';
import LeadForm from './Form.js';
import {Carousel, Form, Button, Col, Container, Row, Navbar, Nav, Popover} from 'react-bootstrap';
import WhatsAppIcon from './whatsapp/WhatsAppIcon';

function App() { 
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const bgStyles = {backgroundImage: 'url('+bg+')'};
  const [otherActive, setOtherActive] = useState(false);
  const [start, setStart] = useState(false);
  const [step, setStep] = useState(0);
  const [values, setValues] = useState([]);

  useEffect(() => {
    console.log(values);
  });
  
  const popoverPlacement = window.innerWidth >= 768 ? 'bottom' : 'right';
  
  const renderStepFields = (step_data) => {
      switch (step_data.type) {
          case "select":
            return renderSelectField(step, step_data.select);
          break;
          case "text":
            return renderTextField(step);
          break;
          case "final":
            return renderFinalField();
          break;
      }
  }
  
  const renderFinalField = () => {
      return (
        <Row>
            <Col md="6" className="align-self-center">
                <p>{steps[step].desc}</p>
                <h5>{steps[step].desc2}</h5>
            </Col>
            <Col md="6">
                <LeadForm additional={values} />
            </Col>
        </Row>
      )
  }
  
  const renderTextField = (step) => {
      return (
        <Form.Control type="text" name={"step-"+step+"-text"} onKeyUp={textHandler} defaultValue={(values[step] == 'другое') ? '' : values[step]} />
      )
  }
  
  const renderSelectField = (step, list) => {
      return (
        <div>
            <Row className="step-select-field">
                {list.map((e, i) => (<Col md="6"><div className="form-check-container"><Form.Check onChange={selectHandler} label={e} checked={values[step] == e} type="radio" name={"step-"+step+"-select"} id={"step-"+step+"-"+i+"-select"} /></div></Col>))}
            </Row>
            { otherActive ? (
            <div className="step-select-field">
                <h5>Опишите подробнее</h5>
                <Form.Control type="text" name={"step-"+step+"-text"} onKeyUp={textHandler} defaultValue={values[step]} />
            </div>
            ) : '' }
        </div>
      )
  }
  
  const prevBtnHandler = () => {
      setStep(step > 0 ? (step-1) : step);
  }
  
  const nextBtnHandler = () => {
      setStep((step + 1) < steps.length ? (step+1) : step);
      setOtherActive(false);
  }

  const selectHandler = (e) => {
      let value = e.target.nextSibling.innerText;
      
      values[step] = e.target.nextSibling.innerText;
      setValues(values);
      
      if (value != 'другое')
        nextBtnHandler();
      else {
        setOtherActive(true);
      }
  }
  
  const textHandler = (e) => {
      values[step] = e.target.value;
      setValues(values);
      
      forceUpdate();
  }
  
  const steps = [
    {
        title: 'Укажите название обманувшей Вас компании?',
        type: 'text',
        required: true
    },
    {
        title: 'Какой деятельностью занимается обманувшая Вас компания',
        type: 'select',
        required: true,
        select: [
            'инвестиционный фонд',
            'брокер',
            'криптовалютный кошелек/биржа',
            'другое'
        ]
    },{
        title: 'Как давно Вы потеряли деньги?',
        type: 'select',
        required: true,
        select: [
            'менее месяца',
            'до полугода',
            'менее года',
            'более чем год'
        ]
    },{
        title: 'Признают ли мошенники наличие Ваших средств?',
        type: 'select',
        required: true,
        select: [
            'нет, мой депозит/счет тем или иным образом был слит/обнулен',
            'да, компания признает наличие денег но отказывается их выводить'
        ]
    },{
        title: 'Насколько большую сумму Вы потеряли?',
        type: 'select',
        required: true,
        select: [
            'до 5000$',
            'от 5000$ до 10000$',
            'от 10000$ до 50000$',
            'более 50000$'
        ]
    },{
        title: 'Как Вы передавали средства мошенникам?',
        type: 'select',
        required: true,
        select: [
            'наличными',
            'переводом на карту',
            'банковской транзакцией',
            'с помощью криптовалюты'
        ]
    },{
        title: 'Есть ли у Вас документы подтверждающие сотрудничество с обманувшей Вас компанией?',
        type: 'select',
        required: true,
        select: [
            'да, есть договор',
            'есть текст договора, но нет заверенной копии',
            'есть квитанции, чеки и пр.',
            'нет никаких документов'
        ]
    },{
        title: 'Опишите важные и полезные с Вашей точки зрения подробности Вашего случая',
        required: false,
        type: 'text'
    },{
        title: 'Оставьте пожалуйста свои контактные данные',
        desc: 'Мы соберем и проанализируем всю необходимую информацию и свяжемся с Вами. Вы получите абсолютно бесплатную консультацию. Наша компания не берет предоплаты, мы получаем вознаграждение только после того как Вы получите свои деньги.',
        desc2: 'Время действовать. Пора вернуть Ваши деньги',
        required: false,
        type: 'final'
    }
  ];
  
  const renderSteps = (
    <Row>
        <Col md="7" lg="9" className="p-5 order-1 order-md-0">
            <h4 className="mb-5">{steps[step].title}</h4>
            <div className="step-content pb-5">
                {renderStepFields(steps[step])}
            </div>
            <Row className="steps-info p-4 mt-5">
                <Col md="5" className="col-12 text-center text-md-left mb-3 mb-md-0">Шаг {step+1} из {steps.length}</Col>
                <Col md="7" className="col-12 text-center text-md-right">
                    {(step != 0) ? (<Button variant="warning" className="mr-2" onClick={prevBtnHandler}>
                        Назад
                    </Button>) : ''}
                    {((step+1) != steps.length) ? (<Button onClick={nextBtnHandler} disabled={(steps[step].required == true) && !values[step]}>
                        Далее
                    </Button>) : ''}
                </Col>
            </Row>
        </Col>
        <Col md="5" lg="3" className="p-0 bg-light px-3 side-content order-2 order-md-1">
            <Row className="mt-3">
                <Col md="12" xs="12">
                    <Row className="align-items-center p-3 p-md-0">
                        <Col xs="3" md="5">                 
                            <img src={man_img} className="img-fluid" />
                        </Col>
                        <Col xs="9" md="7">                 
                            <h6 className="m-0">Иван Ульянов</h6>
                            <p className="text-muted m-0">Юрист</p>                          
                        </Col>
                    </Row>                  
                </Col>
                <Col md="12" xs="12" className="popover-container p-3">
                    <Row className="align-items px-3">
                        <Col md="12">                 
                            <Popover placement='bottom' padding={20} className="p-3 mt-0 mt-md-3">
                                Для лучшего понимания Вашей ситуации и подготовки бесплатной консультации просим Вас ответить на ряд простых вопросов. Это не займет много Вашего времени. Также обращаем Ваше внимание на тот факт, что вся полученная от Вас информация является строго конфиденциальной. Консультация является бесплатной, мы не берем предоплаты с наших клиентов.
                            </Popover>  
                        </Col>
                    </Row>                     
                </Col>
            </Row>
        </Col>
    </Row>
  )
  return start ? (
    <div className="quiz-container justify-content-center p-md-5 d-flex">
        <Container className="quiz-inner p-0">
            {renderSteps}
        </Container>
        <WhatsAppIcon />
    </div>
  ) : (
    <div className="App">
        <Row className="fullscreen-block m-0" style={bgStyles}>
            <Col md="8" lg="6" className="offset-0 offset-md-4 offset-lg-6 align-self-center p-5 p-md-4">
                <h2 className="mb-3">Юридическая помощь в возвращении денег</h2>
                <h2 className="mb-3">Оперативно. Конфиденциально. Квалифицированно.</h2>
                <p className="mb-5">Оказываем помощь в возвращении денег в случаях мошенничества брокера, инвестиционной компании, криптовалютной биржи или кошелька, а также во множестве других случаев.</p>
                <a className="glass-btn text-center" onClick={() => setStart(true)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    Начать возврат средств
                </a>
            </Col>
        </Row>
        <WhatsAppIcon />
    </div>
  );
}

export default App;
